import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";
import Form from "/src/components/forms/Form";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";
import Col from "../../components/styled/grid/Col";
import {Body, Title} from "../../components/styled/typography/Typography";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const TrainingForm = ({data}) => {

    const {training} = data

    return (
        <Layout lang={training.lang} seo={training.SEO}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid customBackground="middle">
                <Col>
                    <Title color="white" marginBottom="normal">
                        {training.title}
                    </Title>
                    {training.description &&
                    <Body color="body-text">
                        {parse(training.description)}
                    </Body>
                    }
                </Col>
                <Col>
                    <Form form={training} lang={training.lang} isTraining={true}/>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleTrainingForm($locale: String) {
         training: strapiTrainingForms(lang: { eq: $locale }) {
          lang
          title
          Form {
            company
            company_address
            contact_person_email
            contact_person_name
            contact_person_phone
            country
            email
            first_name
            help_dropdown
            job_title
            last_name
            message
            phone
            preffered_dropdown
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default TrainingForm